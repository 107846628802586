<template>
  <div
    class="main-sidebar tw-p-5 tw-bg-white tw-flex-col tw-justify-between tw-h-full sm:tw-max-w-xs tw-transition-all tw-border-r-2 tw-border-r-gray-100 tw-relative tw-z-30"
    :class="[$parent.$data.openSideNav ? 'show' : 'hide']"
  >
    <div class="">
      <router-link
        to="https://salonial.com"
        class="tw-mb-8 tw-hidden md:tw-block"
      >
        <img
          alt="Logo"
          src="~@/assets/images/logo.png"
          class="tw-w-14 tw-aspect-square tw-mx-auto tw-my-4"
        />
        <p class="username inter-bold text-center">
          {{
            user.roles === "admin_salon" ? "Mitra Salonial" : "Admin Salonial"
          }}
        </p>
      </router-link>

      <div class="tw-block md:tw-hidden">
        <img
          alt="Logo"
          src="~@/assets/images/logo.png"
          class="tw-w-14 tw-aspect-square tw-my-4"
        />
        <div class="tw-flex tw-justify-between tw-items-center">
          <div>
            <p class="inter-bold">
              {{
                user.roles === "admin_salon"
                  ? "Mitra Salonial"
                  : "Admin Salonial"
              }}
            </p>
            <p>{{ user.email }}</p>
          </div>
          <button
            class="tw-flex tw-items-center tw-gap-1 tw-ml-2"
            @click="submitLogout()"
          >
            <i class="pi pi-sign-out"></i>
          </button>
        </div>
      </div>

      <hr class="tw-mt-8 tw-mb-8 tw-block md:tw-hidden" />

      <AppSubmenu
        :items="model"
        class="layout-menu"
        :root="true"
        @menuitem-click="onMenuItemClick"
        @keydown="onKeyDown"
      />
    </div>
  </div>
  <div
    class="sidebar-overlay"
    :class="$parent.$data.openSideNav ? 'show' : 'hide'"
    @click="onMenuToggle"
  ></div>
</template>

<script>
import { useCustomAuth } from "@/composables/auth";
import { useRouter } from "vue-router";
import AppSubmenu from "./AppSubmenu";
import { inject } from "vue";

export default {
  setup() {
    const { logout } = useCustomAuth();
    const router = useRouter();
    const isOnboarded = inject("isOnboarded");

    const submitLogout = () => {
      router.push({ name: "login" });
      logout();
      window.location.reload();
    };

    return { submitLogout, isOnboarded };
  },
  props: {
    model: Array,
    user: Object,
    salonName: String,
    isAdmin: Boolean,
  },
  components: {
    AppSubmenu: AppSubmenu,
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
  data() {
    return {};
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },

    onKeyDown(event) {
      const nodeElement = event.target;
      if (event.code === "Enter" || event.code === "Space") {
        nodeElement.click();
        event.preventDefault();
      }
    },
    bannerImage() {
      return this.$appState.darkTheme
        ? "images/banner-primeblocks-dark.png"
        : "images/banner-primeblocks.png";
    },
  },
};
</script>

<style lang="scss" scoped>
.main-sidebar {
  z-index: 101;
  transition: all 0.5s ease-in-out;
  overflow: scroll;
  .username {
    transition: all 0.1s ease-in-out;
  }
  @media (min-width: 768px) {
    &.show {
      width: 320px;
      :deep(.nested-icon) {
        display: none;
      }
    }
    &.hide {
      width: 81px;
      .username {
        opacity: 0;
        display: none;
      }
      :deep(.menu-label) {
        display: none;
      }
      :deep(.menuitem-toggle-icon) {
        display: none;
      }
      :deep(.layout-menu li ul ul) {
        padding-left: 0;
      }
      :deep(.nested-icon) {
        display: block;
      }
    }
  }
}
@media (max-width: 767px) {
  .main-sidebar {
    position: fixed;
    width: 300px;
    &.show {
      left: 0;
    }
    &.hide {
      left: -320px;
    }
  }
  .sidebar-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 100;
    &.show {
      display: block;
    }
    &.hide {
      display: none;
    }
  }
}
</style>
