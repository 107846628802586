<template>
  <div :class="containerClass" @click="onWrapperClick">
    <main class="tw-flex tw-h-screen tw-overflow-y-hidden tw-relative">
      <div
        :class="{
          'tw-block': !is_onboarded,
          'tw-hidden': is_onboarded,
        }"
      >
        <Onboarding @next="next()" @skip="skip()" :user="user" />
      </div>
      <AppMenu
        :model="menu"
        :isAdmin="isAdministrator"
        :salonName="salon_name"
        :user="user"
        @menuitem-click="onMenuItemClick"
        @menu-toggle="onMenuToggle"
        @next="next"
      />

      <div
        class="content-container tw-h-screen tw-overflow-y-auto tw-flex-1 tw-flex tw-flex-col tw-w-full tw-overflow-x-hidden"
      >
        <AppTopBar @menu-toggle="onMenuToggle" :user="user" />
        <div class="layout-main tw-flex-1 tw-bg-white tw-z-0">
          <router-view />
        </div>
        <AppFooter />
      </div>

      <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />

      <transition name="layout-mask">
        <div
          class="layout-mask p-component-overlay"
          v-if="mobileMenuActive"
        ></div>
      </transition>
    </main>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar";
import AppMenu from "./AppMenu";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";
import Onboarding from "./Onboarding.vue";
import { useAuthRole } from "@/composables/auth";
import { ref, provide } from "vue";

export default {
  emits: [
    "change-theme",
    "menuitemClick",
    "menuitem-click",
    "menuToggle",
    "menu-toggle",
    "next",
    "isAdmin",
    "is-admin",
  ],

  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppConfig: AppConfig,
    AppFooter: AppFooter,
    Onboarding: Onboarding,
  },

  setup() {
    const { isAdminPriti, getUser } = useAuthRole();
    const isOnboarded = ref(false);
    const step = ref(0);

    provide("isOnboarded", isOnboarded);
    provide("step", step);

    return { isAdminPriti, getUser, isOnboarded, step };
  },

  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [],
      salon_name: "",
      salon_status: null,
      openSideNav: true,
      is_onboarded: false,
    };
  },

  watch: {
    $route() {
      this.menuActive = false;
    },
    salon_status(newValue) {
      this.updateMenu(this.isAdminPriti(), newValue);
    },
  },

  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },

    isAdministrator() {
      let isAdmin = this.isAdminPriti();
      this.updateMenu(isAdmin);

      return isAdmin;
    },

    user() {
      const user = this.getUser();
      if (user && user.salon_id && user.salon_id !== 0) {
        this.getSalonName(user.salon_id);
        this.updateMenu(false);
        this.getOnboarded(user.is_onboarded);
      }
      return user;
    },
  },

  created() {
    document.title = process.env.VUE_APP_TITLE;
    this.openSideNav = screen.width >= 768;
  },

  beforeUpdate() {
    if (this.mobileMenuActive) {
      this.addClass(document.body, "body-overflow-hidden");
    } else {
      this.removeClass(document.body, "body-overflow-hidden");
    }
  },

  methods: {
    getOnboarded(isOnboarded) {
      this.is_onboarded = isOnboarded; //to set status
    },

    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },

    onMenuToggle() {
      this.openSideNav = !this.openSideNav;
    },

    onSidebarClick() {
      this.menuClick = true;
    },

    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },

    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },

    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },

    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },

    isDesktop() {
      return window.innerWidth >= 992;
    },

    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },

    getSalonName(salonID = null) {
      if (!salonID) return;

      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/salons/${salonID}`)
        .then((response) => {
          if (response.status === 200) {
            this.salon_name = response.data.data.name;
            this.salon_status = response.data.data.salons[0].status;
          }
        });
    },

    skip() {
      this.isOnboarded = true;
    },

    next() {
      if (this.step < this.menu.length) {
        this.step++;
      } else {
        localStorage.setItem("isOnboarded", true);
        this.isOnboarded = true;
      }
    },

    updateMenu(isAdminPriti) {
      let customTabs = [
        {
          label: isAdminPriti ? "Salon" : "Profil Salon",
          icon: "pi pi-fw pi-building",
          to: { name: isAdminPriti ? "salons" : "salon" },
        },
        isAdminPriti
          ? {
              label: "Kategori",
              icon: "pi pi-fw pi-bookmark",
              to: { name: "categories" },
            }
          : null,
      ];

      let approvedMenu = [
        {
          label: "Layanan Salon",
          icon: "pi pi-fw pi-tags",
          to: { name: "salon-services" },
        },
        {
          label: "Kepegawaian",
          icon: "pi pi-fw pi-user",
          items: [
            {
              label: "Daftar Pegawai",
              icon: "pi pi-fw pi-list nested-icon",
              to: { name: "barbers" },
            },
            {
              label: "Jadwal Pegawai",
              icon: "pi pi-fw pi-clock nested-icon",
              to: { name: "schedule-barbers" },
            },
          ],
        },
        {
          label: "Pesanan",
          icon: "pi pi-fw pi-book",
          to: { name: "invoices" },
        },
        {
          label: "Tanya Jawab",
          icon: "pi pi-fw pi-question",
          to: { name: isAdminPriti ? "all_qna" : "qna" },
        },
      ];

      let reportMenu = [
        {
          label: "Daftar Pegawai",
          description:
            "isi dan atur siapa saja pegawai yang akan memberikan pelayanan",
          items: [
            {
              label: "Laporan Salon",
              icon: "pi pi-chart-bar",
              to: { name: "salon-report" },
            },
            {
              label: "Laporan Barber",
              icon: "pi pi-chart-line",
              to: { name: "barber-report" },
            },
          ],
        },
      ];

      this.menu = [
        {
          label: "Layanan Salon",
          description:
            "Isi dan atur layanan salon apa saja yang ada di aplikasinya nanti",
          items: [
            {
              label: "Dashboard",
              icon: "pi pi-fw pi-home",
              to: { name: "dashboard" },
            },
            ...customTabs,
            ...approvedMenu,
          ],
        },
        ...reportMenu,
        {
          label: "Jadwal Pegawai",
          description:
            "Isi dan atur jadwal agar layanan aktif sehingga dapat dipesan melalui aplikasi",
          items: [
            {
              label: "Administrator",
              icon: "pi pi-fw pi-users",
              to: { name: "admins" },
            },
            isAdminPriti && {
              label: "Pengguna",
              icon: "pi pi-fw pi-trash",
              to: { name: "deleted-accounts" },
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "./App.scss";
@import "Tailwind.css";

@media (max-width: 767px) {
  .content-container {
    left: 0;
  }
}
</style>
