<template>
  <div
    class="tw-w-full tw-flex tw-items-center tw-justify-between tw-shadow tw-p-3 tw-bg-white tw-sticky tw-top-0 tw-left-0 tw-z-10"
  >
    <router-link
      to="https://salonial.com"
      class="tw-flex align-items-center md:tw-hidden"
    >
      <img
        alt="Logo"
        src="~@/assets/images/logo.png"
        class="tw-w-10 tw-aspect-square tw-mx-auto mr-3"
      />
      <p
        class="tw-text-center inter-semibold mb-0"
        v-if="user.roles === 'admin_salon'"
      >
        Mitra Salonial
      </p>
      <p class="tw-text-center inter-semibold mb-0" v-else>Admin Salonial</p>
    </router-link>

    <button
      type="button"
      class="tw-border-gray-100 tw-text-gray-600 tw-border-2 hover:tw-bg-gray-200 tw-flex tw-items-center tw-gap-2 tw-justify-center tw-py-2 tw-px-3 tw-rounded-md tw-transition-all"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars tw-text-lg"></i>
    </button>

    <ul class="tw-items-center tw-gap-4 tw-hidden md:tw-flex">
      <li class="relative tw-border-r-2 tw-border-r-gray-100 tw-pr-5">
        <a href="#" class="" @click.prevent="showNotification">
          <i
            class="pi pi-bell p-text-secondary"
            style="font-size: 1.5rem"
            v-if="!data.length"
          ></i>
          <i
            class="pi pi-bell p-text-secondary"
            style="font-size: 1.5rem"
            v-badge.danger
            v-else
          ></i>
        </a>
        <div class="absolute menu-overlay" v-show="show_menu">
          <template v-if="data.length">
            <a
              href="#"
              class="block px-3 item"
              :class="{ active: !item.is_read }"
              v-for="(item, index) in data"
              :key="`_${index}`"
            >
              <div class="px-2 py-3 pt-3">
                <h6 class="tw-font-bold">
                  {{ item.title }}
                </h6>
                <p class="ellipsis-2">
                  {{ item.message }}
                </p>
              </div>
              <Divider class="my-0" />
            </a>
            <h6 class="my-3 text-center">
              <a
                href="notification"
                class="tw-text-blue-400"
                @click.prevent="toNotification()"
              >
                Show all
              </a>
            </h6>
          </template>
          <template v-else>
            <div class="px-4 py-5 text-center">Empty</div>
          </template>
        </div>
      </li>

      <li>
        <div class="tw-flex tw-items-center tw-gap-2 tw-flex-1">
          <div
            :class="`relative profile ${true ? '' : 'tw-mx-auto'}`"
            :style="{
              'background-image': `url(${
                form.image ? form.image : user.photo_profile
              })`,
            }"
          >
            <i class="pi pi-user" v-if="!form.image && !user.photo_profile"></i>
            <div
              href="#"
              class="upload loading align-items-center justify-content-center tw-flex-col"
              v-if="form.loading"
            >
              <i class="pi pi-spin pi-spinner tw-text-white tw-text-3xl"></i>
            </div>
            <div
              href="#"
              class="upload align-items-center justify-content-center tw-flex-col"
              v-else
            >
              <i class="pi pi-pencil tw-text-white tw-text-lg"></i>
              <label
                class="tw-text-white tw-font-semibold tw-text-base tw-cursor-pointer"
                >Edit</label
              >
              <input type="file" class="" @input="uploadFile" />
            </div>
          </div>

          <div
            class="text-left tw-flex tw-flex-col tw-items-start tw-justify-center"
          >
            <h6 class="mb-1 tw-font-semibold">
              <span v-if="isAdminPriti()"> Admin Salonial </span>
              <span v-else> Admin {{ salonName }} </span>
            </h6>
            <h6 class="tw-text-gray-400 tw-text-sm">
              {{ user ? user.email : "" }}
            </h6>
          </div>
        </div>
      </li>

      <li>
        <button
          class="tw-flex tw-items-center tw-gap-1 tw-ml-2"
          @click="submitLogout()"
        >
          <i class="pi pi-sign-out"></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { useAuthRole, useCustomAuth } from "@/composables/auth";
import { useRouter } from "vue-router";
export default {
  setup() {
    const { isAdminPriti } = useAuthRole();
    const { logout } = useCustomAuth();

    const router = useRouter();

    const submitLogout = () => {
      router.push({ name: "login" });
      logout();
      window.location.reload();
    };

    return { isAdminPriti, submitLogout };
  },
  props: ["user"],
  data() {
    return {
      data: [],
      show_menu: false,
      form: {
        loading: false,
        image: "",
      },
    };
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
    title() {
      return process.env.VUE_APP_TITLE;
    },
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    getNotifications() {
      this.$http
        .get(
          `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/notification/list?type&is_read&limit=5&offset=0`
        )
        .then((response) => {
          this.data = response.data.data.notifications;
        });
    },
    showNotification() {
      this.show_menu = !this.show_menu;
    },
    toNotification() {
      this.show_menu = false;
      this.$router.push({ name: "notifications" });
    },

    uploadFile(event) {
      let files = event.target.files;
      if (!files.length) return;

      let reader = new FileReader();
      let formData = new FormData();
      let vm = this;

      reader.onload = (e) => {
        vm.form.image = e.target.result;
        vm.form.loading = true;

        formData.append("image", files[0]);

        vm.$http
          .post(
            `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user/${vm.user.user_id}/admin/upload-image`,
            formData
          )
          .then((response) => {
            vm.form.loading = false;
            vm.$toast.add({
              severity: "success",
              closable: true,
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
          })
          .catch((e) => {
            vm.$toast.add({
              severity: "error",
              closable: true,
              summary: "Error",
              detail: e.response.data.message,
              life: 3000,
            });
          });
      };
      reader.readAsDataURL(files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-overlay {
  min-width: 280px;
  background-color: #fff;
  right: 0;
  border-radius: 10px;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%),
    0px 1px 4px rgb(0 0 0 / 8%);

  .item.active {
    background-color: azure;
  }
}

.profile {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  > i {
    font-size: 12px;
    color: grey;
  }

  .upload {
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &:hover .upload,
  .upload.loading {
    position: absolute;
    display: flex;
    background-color: rgba(0, 0, 0, 0.3);
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
  }

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
}
</style>
