import { createRouter, createWebHistory } from "vue-router";
import App from "@/apps/App.vue";

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("./pages/Landing.vue"),
  },
  {
    path: "/app",
    name: "app",
    component: App,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/dashboard/Dashboard.vue"),
      },
      {
        path: "salons",
        name: "salons-base",
        component: () => import("./components/dashboard/Base"),
        meta: {
          auth: {
            roles: "admin_priti",
          },
        },
        children: [
          {
            path: "",
            name: "salons",
            component: () => import("./pages/salons/ListFilter"),
          },
          {
            path: "create",
            name: "create-salon",
            component: () => import("./pages/salons/Create"),
          },
          {
            path: ":id",
            name: "detail-salon",
            component: () => import("./pages/salons/Detail"),
          },
          {
            path: ":id/edit",
            name: "edit-salon",
            component: () => import("./pages/salons/Edit"),
          },
        ],
      },
      {
        path: "salon",
        name: "salon-base",
        component: () => import("./components/dashboard/Base"),
        meta: {
          auth: {
            roles: "admin_salon",
            redirect: { name: "salons" },
          },
        },
        children: [
          {
            path: "",
            name: "salon",
            component: () => import("./pages/salons/Detail"),
          },
          {
            path: "edit",
            name: "edit-salon-admin",
            component: () => import("./pages/salons/Edit"),
          },
        ],
      },
      {
        path: "services",
        name: "salon-services-base",
        component: () => import("./components/dashboard/Base"),
        children: [
          {
            path: "",
            name: "salon-services",
            component: () => import("./pages/salon-services/ListFilter"),
          },
          {
            path: ":id",
            name: "detail-salon-service",
            props: true,
            component: () => import("./pages/salon-services/Detail"),
          },
          {
            path: "create",
            name: "create-salon-service",
            component: () => import("./pages/salon-services/Form"),
          },
          {
            path: ":id/edit",
            name: "edit-salon-service",
            props: true,
            component: () => import("./pages/salon-services/Form"),
          },
        ],
      },
      {
        path: "categories",
        name: "categories-base",
        component: () => import("./components/dashboard/Base"),
        children: [
          {
            path: "",
            name: "categories",
            props: true,
            component: () => import("./pages/categories/ListFilter"),
          },
          {
            path: ":id",
            name: "detail-category",
            props: true,
            component: () => import("./pages/categories/Detail"),
          },
          {
            path: "create",
            name: "create-category",
            props: true,
            component: () => import("./pages/categories/Form"),
          },
          {
            path: ":id/edit",
            name: "edit-category",
            props: true,
            component: () => import("./pages/categories/Form"),
          },
        ],
      },
      {
        path: "barbers",
        name: "barbers-base",
        component: () => import("./components/dashboard/Base"),
        children: [
          {
            path: "",
            name: "barbers",
            component: () => import("./pages/barbers/ListFilter"),
          },
          {
            path: "create",
            name: "create-barber",
            component: () => import("./pages/barbers/Create"),
          },
          {
            path: ":id",
            name: "detail-barber",
            component: () => import("./pages/barbers/Detail"),
          },
          {
            path: ":id/edit",
            name: "edit-barber",
            component: () => import("./pages/barbers/Edit"),
          },
          {
            path: "schedule",
            name: "schedule-barbers",
            component: () => import("./pages/barbers/Schedule"),
          },
        ],
      },
      {
        path: "invoices",
        name: "invoices-base",
        component: () => import("./components/dashboard/Base"),
        children: [
          {
            path: "",
            name: "invoices",
            component: () => import("./pages/invoices/ListFilter"),
          },
          {
            path: ":id",
            name: "detail-invoice",
            component: () => import("./pages/invoices/Detail"),
          },
        ],
      },
      {
        path: "admins",
        name: "admins-base",
        component: () => import("./components/dashboard/Base"),
        children: [
          {
            path: "",
            name: "admins",
            component: () => import("./pages/admins/ListFilter"),
          },
          {
            path: ":id",
            name: "detail-admin",
            props: true,
            component: () => import("./pages/admins/Detail"),
          },
          {
            path: "create",
            name: "create-admin",
            component: () => import("./pages/admins/Form"),
          },
          {
            path: ":id/edit",
            name: "edit-admin",
            props: true,
            component: () => import("./pages/admins/Form"),
          },
        ],
      },
      {
        path: "notifications",
        name: "notifications",
        props: true,
        component: () => import("./pages/notification/Notification"),
      },
      {
        path: "report/salon",
        name: "salon-report",
        props: true,
        component: () => import("./pages/report/SalonReport"),
      },
      {
        path: "report/barber",
        name: "barber-report",
        props: true,
        component: () => import("./pages/report/BarberReport"),
      },
      {
        path: "qna",
        // name: 'qna-base',
        component: () => import("./components/dashboard/Base"),
        meta: {
          auth: {
            roles: "admin_salon",
            redirect: { name: "all_qna" },
          },
        },
        children: [
          {
            path: "",
            name: "qna",
            component: () => import("./pages/qna/Detail"),
          },
        ],
      },
      {
        path: "qna",
        name: "qna-base",
        component: () => import("./components/dashboard/Base"),
        meta: {
          auth: {
            roles: "admin_priti",
          },
        },
        children: [
          {
            path: "",
            name: "all_qna",
            component: () => import("./pages/qna/ListFilter"),
          },
          {
            path: ":id",
            name: "qna-salon",
            component: () => import("./pages/qna/Detail"),
          },
        ],
      },
      {
        path: "accounts",
        name: "deleted-accounts",
        component: () => import("./pages/accounts/ListFilter"),
        meta: {
          auth: {
            roles: "admin_priti",
          },
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: {
      auth: false,
    },
    component: () => import("./pages/auth/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./pages/auth/register/Register.vue"),
    children: [
      {
        path: "",
        name: "register-step-1",
        component: () => import("./pages/auth/register/Step1.vue"),
      },
      {
        path: "/register/location",
        name: "register-step-2",
        component: () => import("./pages/auth/register/Step2.vue"),
      },
      {
        path: "/register/admin",
        name: "register-step-3",
        component: () => import("./pages/auth/register/Step3.vue"),
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("./pages/auth/ForgotPassword.vue"),
  },
  {
    path: "/forgot-password/verify/:token",
    name: "verify-forgot-password",
    props: true,
    component: () => import("./pages/auth/VerifyForgotPassword.vue"),
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    props: true,
    component: () => import("./pages/auth/ResetPassword.vue"),
  },
  {
    path: "/term-and-condition",
    name: "term-and-condition",
    meta: {
      auth: false,
    },
    component: () => import("./pages/TermAndCondition.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    meta: {
      auth: false,
    },
    component: () => import("./pages/PrivacyPolicy.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    name: "notfound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
