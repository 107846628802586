<template>
  <div class="layout-footer">
<!--    <div class="">
      <img alt="Logo" src="~@/assets/images/logo.png" class="mr-2" width="50" />
    </div>
-->
    <div class="tw-ml-2">
      &copy; {{ new Date().getFullYear() }} 
    </div>
    <span class="ml-2 font-medium">Salonial | Salonial adalah merek milik PT Samden Cipta Digital. Terdaftar pada Direktorat Jendral Kekayaan Intelektual Republik Indonesia.</span>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    }
  },
  methods: {},
}
</script>